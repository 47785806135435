//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { PreferenceService } from "../../../services/preference.service";
import { jSith } from "../../../util/jquery-replacement";

@TypeManagerDecorator("s25-ng-publisher-settings")
@Component({
    selector: "s25-ng-publisher-settings",
    template: `
        <div *ngIf="!this.initLoading" class="c-margin-left--half">
            <h2 class="c-margin-bottom--single">Publisher Settings</h2>
            <div class="ngBold c-margin-bottom--quarter">Default Publisher Display Options</div>

            <div *ngFor="let d of this.displayOptions; let i = index">
                <label class="c-displayBlock" [for]="d.id">
                    <input type="checkbox" [id]="d.id" [checked]="d.checked" (change)="onOptionsChange($event, i)" />
                    {{ d.name }}
                </label>
            </div>

            <div class="c-margin-bottom--single c-margin-top--single">
                <div class="ngBold c-margin-top--half">Event Publishing Date Range</div>
                <div class="ngFinePrint c-margin-bottom--half">
                    When publishing event feeds, select events from the following date range:
                </div>
                <div class="c-margin-top--quarter">
                    <input
                        type="number"
                        min="0"
                        max="99999"
                        id="pubEventWindowFuture"
                        [(ngModel)]="this.pubEventWindowFuture"
                        (ngModelChange)="this.onChange()"
                        name="pubEventWindowFuture"
                        class="c-input c-margin-right--half"
                    />
                    <label for="pubEventWindowFuture">days in the future</label>
                </div>
                <div class="c-margin-top--single">
                    <input
                        type="number"
                        min="0"
                        max="99999"
                        id="pubEventWindowPast"
                        [(ngModel)]="this.pubEventWindowPast"
                        (ngModelChange)="this.onChange()"
                        name="pubEventWindowPast"
                        class="c-input c-margin-right--half"
                    />
                    <label for="pubEventWindowPast">days in the past</label>
                </div>
            </div>

            <div class="ngBold c-margin-top--half">Events-By-Location Publishing Date Range</div>
            <div class="ngFinePrint c-margin-bottom--half">
                When publishing event feeds based on location, select events from the following date range:
            </div>
            <div class="c-margin-top--quarter">
                <input
                    type="number"
                    min="0"
                    max="99999"
                    id="pubSpaceWindowFuture"
                    name="pubSpaceWindowFuture"
                    class="c-input c-margin-right--half"
                    [(ngModel)]="this.pubSpaceWindowFuture"
                    (ngModelChange)="this.onChange()"
                />
                <label for="pubSpaceWindowPast">days in the future</label>
            </div>
            <div class="c-margin-top--single">
                <input
                    type="number"
                    min="0"
                    max="99999"
                    id="pubSpaceWindowPast"
                    [(ngModel)]="this.pubSpaceWindowPast"
                    (ngModelChange)="this.onChange()"
                    name="pubSpaceWindowPast"
                    class="c-input c-margin-right--half"
                />
                <label for="pubSpaceWindowPast">days in the past</label>
            </div>

            <div *ngIf="isInValid" class="ngRed ngBold c-padding-top--single">Please enter a valid integer number.</div>

            <div class="c-displayBlock c-padding-bottom--double">
                <button
                    class="aw-button aw-button--primary c-margin-top--single c-margin-right--quarter"
                    (click)="save()"
                    [disabled]="isInValid"
                >
                    {{ this.loading ? "Saving...." : "Save" }}
                </button>
                <button class="aw-button aw-button--outline c-margin-top--single" (click)="cancel()">Cancel</button>
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublisherSettingsComponent implements OnInit {
    displayOptions: any = [
        { id: "use_event_title", name: "Show event title (rather than event name)" },
        {
            id: "rsrv_service",
            name: "Publish only occurrences with location assignments (as opposed to all occurrences)",
        },
        { id: "space_formal_name", name: "Show location formal name (rather than short name)" },
        { id: "show_rsrv_comments", name: "Include reservation comments" },
        { id: "setup_takedown", name: "Include setup and takedown times" },
    ];

    loading: boolean = false;
    initLoading: boolean = true;

    pubSpaceWindowFuture: number;
    pubSpaceWindowPast: number;
    pubEventWindowFuture: number;
    pubEventWindowPast: number;

    initPubSpaceWindowFuture: number;
    initPubSpaceWindowPast: number;
    initPubEventWindowFuture: number;
    initPubEventWindowPast: number;
    initPubDisplayOptions: any;

    isInValid: boolean = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
        this.getPrefs();
        this.cd.detectChanges();
    }

    getPrefs() {
        return S25Util.all({
            prefs: PreferenceService.getPreferences(
                [
                    "config_pub_display_options",
                    "config_pub_space_window_future",
                    "config_pub_space_window_past",
                    "config_pub_event_window_future",
                    "config_pub_event_window_past",
                ],
                "S",
            ),
        }).then((resp) => {
            let getPubDisplayOptions = (this.initPubDisplayOptions = resp.prefs.config_pub_display_options.value);
            this.initPubSpaceWindowFuture = this.pubSpaceWindowFuture = parseInt(
                resp.prefs.config_pub_space_window_future.value,
            );
            this.initPubSpaceWindowPast = this.pubSpaceWindowPast = parseInt(
                resp.prefs.config_pub_space_window_past.value,
            );
            this.initPubEventWindowFuture = this.pubEventWindowFuture = parseInt(
                resp.prefs.config_pub_event_window_future.value,
            );
            this.initPubEventWindowPast = this.pubEventWindowPast = parseInt(
                resp.prefs.config_pub_event_window_past.value,
            );
            if (getPubDisplayOptions != "") {
                let array = getPubDisplayOptions.split("+");
                this.decorateOptions(array);
            }
            this.initLoading = false;
            this.cd.detectChanges();
        });
    }

    decorateOptions(getPubOptions: any) {
        let ret: any = [];
        jSith.forEach(this.displayOptions, (key: any, g: any) => {
            let checked = getPubOptions.filter((item: any) => item === g.id);
            checked.length > 0 ? ret.push({ ...g, checked: true }) : ret.push({ ...g, checked: false });
        });
        this.displayOptions = ret;
    }

    onChange() {
        this.isInValid =
            !Number.isInteger(this.pubSpaceWindowFuture) ||
            !Number.isInteger(this.pubSpaceWindowPast) ||
            !Number.isInteger(this.pubEventWindowFuture) ||
            !Number.isInteger(this.pubEventWindowPast);

        this.cd.detectChanges();
    }

    onOptionsChange(event: any, i: number) {
        this.displayOptions[i].checked = event.target.checked;
        this.cd.detectChanges();
    }

    save() {
        this.loading = true;
        this.cd.detectChanges();
        // set the config_pub_display_options value in right format
        let setPubDisplayOptions: any = [];
        this.displayOptions.map(function (a: any) {
            if (a.checked === true) {
                setPubDisplayOptions.push(a.id);
            }
        });
        setPubDisplayOptions = setPubDisplayOptions.join("+");

        let promiseArr = [];
        this.initPubSpaceWindowFuture !== this.pubSpaceWindowFuture &&
            promiseArr.push(
                PreferenceService.setPreference("config_pub_space_window_future", this.pubSpaceWindowFuture, "S"),
            );
        this.initPubSpaceWindowPast !== this.pubSpaceWindowPast &&
            promiseArr.push(
                PreferenceService.setPreference("config_pub_space_window_past", this.pubSpaceWindowPast, "S"),
            );
        this.initPubEventWindowFuture !== this.pubEventWindowFuture &&
            promiseArr.push(
                PreferenceService.setPreference("config_pub_event_window_future", this.pubEventWindowFuture, "S"),
            );
        this.initPubEventWindowPast !== this.pubEventWindowPast &&
            promiseArr.push(
                PreferenceService.setPreference("config_pub_event_window_past", this.pubEventWindowPast, "S"),
            );
        this.initPubDisplayOptions !== setPubDisplayOptions &&
            promiseArr.push(PreferenceService.setPreference("config_pub_display_options", setPubDisplayOptions, "S"));

        if (promiseArr) {
            return S25Util.all(promiseArr).then((resp) => {
                this.initPubDisplayOptions = setPubDisplayOptions; // update initPubDisplayOptions to the latest display Options
                this.loading = false;
                this.cd.detectChanges();
            });
        }
    }

    cancel() {
        this.ngOnInit();
    }
}
